var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{model:{value:(_vm.shippingInstructionDocumentsManagerCurrentTab),callback:function ($$v) {_vm.shippingInstructionDocumentsManagerCurrentTab=$$v},expression:"shippingInstructionDocumentsManagerCurrentTab"}},[_c('v-tab',{key:"standard",staticStyle:{"text-transform":"unset !important"}},[_vm._v(_vm._s(_vm.$t('message.standardDocuments')))]),_c('v-tab',{key:"buyer",staticStyle:{"text-transform":"unset !important"}},[_vm._v(_vm._s(_vm.$t('message.countryCustomerSpecificDocuments')))]),_c('v-tab',{key:"others",staticStyle:{"text-transform":"unset !important"}},[_vm._v(_vm._s(_vm.$t('message.otherRequests')))]),_c('v-tab',{key:"presentation",staticStyle:{"text-transform":"unset !important"}},[_vm._v(_vm._s(_vm.$t('message.presentationDocuments')))]),_c('v-spacer'),_c('ExportTableJsonWithAdd',{staticClass:"mt-3",attrs:{"export-conditions":_vm.exportConditions,"export-data":_vm.exportData,"export-fields":_vm.headers,"export-source":'sidocs',"has-add-function":true},on:{"add-action":_vm.newShippingInstructionDocument}})],1),_c('v-tabs-items',{model:{value:(_vm.shippingInstructionDocumentsManagerCurrentTab),callback:function ($$v) {_vm.shippingInstructionDocumentsManagerCurrentTab=$$v},expression:"shippingInstructionDocumentsManagerCurrentTab"}},[_c('v-tab-item',{key:""},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table col-9",attrs:{"footer-props":{
                        itemsPerPageOptions: [10,25,50,-1],
                        showFirstLastPage: true
                    },"headers":_vm.headers,"hide-default-header":_vm.hideDefaultHeader,"hide-default-footer":_vm.hideDefaultFooter,"items":_vm.documents_standard,"items-per-page":-1,"options":_vm.tableOptions,"calculate-widths":"","dense":"","id":"standardTable","item-key":"Ipa.id"},scopedSlots:_vm._u([{key:"item.Clause.addFilter",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.openAddClauseFilterDialog(item.Clause.id)}}},[_vm._v(_vm._s(_vm.$t('message.addFilter')))])]}},{key:"item.Clause.filters",fn:function(ref){
                    var item = ref.item;
return [_c('v-list',{attrs:{"dense":""}},_vm._l((item.Clause.filters),function(filter){return _c('v-list-item',{key:filter.SdaFilterValue.id,staticClass:"pl-0",attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(filter.SdaFilterValue.filter_name + ' --> ' + filter.SdaFilterValue.filter_value_title)+" ")]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},Object.assign({}, tooltip)),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"},on:{"click":function($event){return _vm.deleteFilter(filter)}}},[_vm._v("cancel")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.deleteFilter')))])])],1)],1)}),1)]}},{key:"item.Clause.id",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openEditClauseDialog(item.Clause.id, item.Clause.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateDocument'))+" ")],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deleteClause(item.Clause.id, item.Clause.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteDocument')))],1)],1)],1)],1)]}}])})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table col-9",attrs:{"footer-props":{
                        itemsPerPageOptions: [10,25,50,-1],
                        showFirstLastPage: true
                    },"headers":_vm.headers,"hide-default-header":_vm.hideDefaultHeader,"hide-default-footer":_vm.hideDefaultFooter,"items":_vm.documents_countryBuyerSpecific,"items-per-page":-1,"options":_vm.tableOptions,"calculate-widths":"","dense":"","id":"countryBuyerSpecificTable","item-key":"Ipa.id"},scopedSlots:_vm._u([{key:"item.Clause.addFilter",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.openAddClauseFilterDialog(item.Clause.id)}}},[_vm._v(_vm._s(_vm.$t('message.addFilter')))])]}},{key:"item.Clause.filters",fn:function(ref){
                    var item = ref.item;
return [_c('v-list',{attrs:{"dense":""}},_vm._l((item.Clause.filters),function(filter){return _c('v-list-item',{key:filter.SdaFilterValue.id,staticClass:"pl-0",attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(filter.SdaFilterValue.filter_name + ' --> ' + filter.SdaFilterValue.filter_value_title)+" ")]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},Object.assign({}, tooltip)),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"},on:{"click":function($event){return _vm.deleteFilter(filter)}}},[_vm._v("cancel")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.deleteFilter')))])])],1)],1)}),1)]}},{key:"item.Clause.id",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openEditClauseDialog(item.Clause.id, item.Clause.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateDocument'))+" ")],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deleteClause(item.Clause.id, item.Clause.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteDocument')))],1)],1)],1)],1)]}}])})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table col-9",attrs:{"footer-props":{
                        itemsPerPageOptions: [10,25,50,-1],
                        showFirstLastPage: true
                    },"headers":_vm.headers,"hide-default-header":_vm.hideDefaultHeader,"hide-default-footer":_vm.hideDefaultFooter,"items":_vm.documents_otherRequests,"items-per-page":-1,"options":_vm.tableOptions,"calculate-widths":"","dense":"","id":"otherRequestsTable","item-key":"Ipa.id"},scopedSlots:_vm._u([{key:"item.Clause.addFilter",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.openAddClauseFilterDialog(item.Clause.id)}}},[_vm._v(_vm._s(_vm.$t('message.addFilter')))])]}},{key:"item.Clause.filters",fn:function(ref){
                    var item = ref.item;
return [_c('v-list',{attrs:{"dense":""}},_vm._l((item.Clause.filters),function(filter){return _c('v-list-item',{key:filter.SdaFilterValue.id,staticClass:"pl-0",attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(filter.SdaFilterValue.filter_name + ' --> ' + filter.SdaFilterValue.filter_value_title)+" ")]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},Object.assign({}, tooltip)),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"},on:{"click":function($event){return _vm.deleteFilter(filter)}}},[_vm._v("cancel")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.deleteFilter')))])])],1)],1)}),1)]}},{key:"item.Clause.id",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openEditClauseDialog(item.Clause.id, item.Clause.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateDocument'))+" ")],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deleteClause(item.Clause.id, item.Clause.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteDocument')))],1)],1)],1)],1)]}}])})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"mt-3 appic-table-light specification-table col-9",attrs:{"footer-props":{
                        itemsPerPageOptions: [10,25,50,-1],
                        showFirstLastPage: true
                    },"headers":_vm.headers,"hide-default-header":_vm.hideDefaultHeader,"hide-default-footer":_vm.hideDefaultFooter,"items":_vm.documents_presentation,"items-per-page":-1,"options":_vm.tableOptions,"calculate-widths":"","dense":"","id":"presentationTable","item-key":"Ipa.id"},scopedSlots:_vm._u([{key:"item.Clause.addFilter",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.openAddClauseFilterDialog(item.Clause.id)}}},[_vm._v(_vm._s(_vm.$t('message.addFilter')))])]}},{key:"item.Clause.filters",fn:function(ref){
                    var item = ref.item;
return [_c('v-list',{attrs:{"dense":""}},_vm._l((item.Clause.filters),function(filter){return _c('v-list-item',{key:filter.SdaFilterValue.id,staticClass:"pl-0",attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(filter.SdaFilterValue.filter_name + ' --> ' + filter.SdaFilterValue.filter_value_title)+" ")]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},Object.assign({}, tooltip)),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"},on:{"click":function($event){return _vm.deleteFilter(filter)}}},[_vm._v("cancel")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.deleteFilter')))])])],1)],1)}),1)]}},{key:"item.Clause.id",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.openEditClauseDialog(item.Clause.id, item.Clause.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateDocument'))+" ")],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){return _vm.deleteClause(item.Clause.id, item.Clause.title)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteDocument')))],1)],1)],1)],1)]}}])})],1)],1)],1),_c('AddShippingInstructionClause',{attrs:{"document-id":_vm.selectedDocumentId,"document-title":_vm.selectedDocumentTitle,"dialog":_vm.editDialog,"page-key":_vm.pageKey,"update-mode":_vm.updateMode},on:{"update:dialog":function($event){_vm.editDialog=$event},"dialog-closed":_vm.editDialogClosed,"update-done":_vm.documentUpdated,"create-done":_vm.documentCreated}}),_c('AddClauseFilter',{attrs:{"clause-id":_vm.selectedDocumentId,"dialog":_vm.addDialog,"page-key":_vm.pageKey2},on:{"update:dialog":function($event){_vm.addDialog=$event},"dialog-closed":_vm.addDialogClosed,"create-done":_vm.filterCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }